<template>
  <div id="data-list-list-view">
         <feather-icon icon="ArrowLeftIcon" class="mb-4" svgClasses="h-8" style="cursor:pointer" @click="$router.push('/Hospital')"/>

    <hospital-Tab class="mt-4" :mainTabName="'HospitalSurgeries'" />
    <div class="vx-col my-5 w-full">
      <vs-button
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="addNewData"
        >{{ $t("AddNew") }}</vs-button
      >
    </div>
    <div>
      <vx-card class="flex-1" v-bind:title="$t('Hospital Surgeries')">
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="HospitalSurgeries"
        >
          <template slot="thead">
            <vs-th> {{ $t("Surgery Name") }}</vs-th>
            <vs-th> {{ $t("Doctor Price") }}</vs-th>
            <vs-th> {{ $t("Hospital Price ") }}</vs-th>

            <vs-th> {{ $t(" Fixed Price") }}</vs-th>
            <vs-th> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate" v-if=" tr.Surgery">
                    {{ tr.Surgery.Name }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.DefaultDoctorPrice">
                    {{ tr.DefaultDoctorPrice }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.DefaultHospitalPrice">
                    {{ tr.DefaultHospitalPrice }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.IsFixedPrice">
                    {{ tr.IsFixedPrice }}
                  </p>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="editData(tr)"
                  />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button type="border" color="danger" :to="{ name: 'Hospital' }">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import hospitalTab from "@/views/tabs/hospitalTab.vue";
export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    hospitalTab,

    // vSelect,
  },

  computed: {
    // HospitalSurgeries() {
    //   debugger
    //   return this.$store.state.HospitalList.HospitalSurgeries;
    // },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        search: {},
        years: [],
      },

      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},

      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
      HospitalSurgeries: [],
    };
  },
  methods: {
    resetData() {
      this.search = {};
      this.search.DateFrom =
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-1";

      this.search.DateTo =
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 1,
          0
        ).getDate();
      const ID = this.$route.params.ID;
      const PatientID = this.$route.params.PatientID;

      if (ID > 0) {
        this.search.DoctorID = ID;
        this.search.IsPrivate = true; // de lw hwa gai mn doctor or patient data  aw 7aga
      } else if (PatientID > 0) {
        this.search.PatientID = PatientID;
        this.search.IsPrivate = true;
        if (this.$route.params.IsAppointment == true) {
          this.search.PaymentStatusID = 2; //Confirm
        } else {
          //pending tasks
          this.search.IsPrivate = false;
          this.search.PaymentStatusID = 1; //Pending
        }
      }
    },
    getHospitalSurgeries(ID) {
      // var ID = ID;
      // if (ID == undefined) {
      //   ID = this.$route.params.ID;
      // }
      // this.search.HospitalID = ID;
debugger
      this.$vs.loading();

      this.$store
        .dispatch("HospitalList/GetHospitalSurgerysByHospitalID",ID)
        .then((res) => {
          debugger
          this.HospitalSurgeries = res.data.Data;
          debugger;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    addNewData() {
      this.$router.push({
        name: "HospitalSurgeries-add/Edit",
        params: { ID: this.$route.params.ID,HospitalSurgeryID:0},
      });
    },

    editData(data) {
      this.$router.push({
        name: "HospitalSurgeries-add/Edit",
        params: { ID: this.$route.params.ID,HospitalSurgeryID:data.ID },
      });
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      // this.$vs.loading();
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    // this.GetSurgery();
    this.resetData();
    const ID = this.$route.params.ID;
    if (ID != undefined) {
      this.getHospitalSurgeries(ID);
    }
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
